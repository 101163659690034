.addButton {
  box-shadow: 0px 2px 4px #00000029;
  border-radius: 4px;
  background: #fff;
  border: none;
  cursor: pointer;
}
.grid {
  width: 100%;
  display: grid;
  padding: 1rem 0;
  grid-template-columns: 1fr 1fr;
}
.flexCenter {
  display: flex;
  align-items: center;
  gap: 8px;
}

.flexEnd {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}
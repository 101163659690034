.basicDiv {
  padding: 2rem;
}
.headerDetails {
  display: grid;
  grid-template-columns: 1fr auto;
}
.headerDetails h2 {
  border-left: 4px solid #607ebb;
}
.headerDetails span {
  margin-left: 12px;
  font-size: 1.3rem;
  font-weight: bold;
}

.headerEnd {
  display: flex;
  gap: 5;
}

.headerButtonDiv {
  margin-right: 1rem;
}

.headerButtonApprove {
  background: #5c5e61;
  color: white;
  padding: 0.5rem 2rem;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 0.5rem;
  outline: none;
  border: none;
  font-size: medium;
  border-radius: 5px;
}

.headerButtonReject {
  background: #bb4040;
  color: white;
  padding: 0.5rem 2rem;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 0.5rem;
  outline: none;
  border: none;
  font-size: medium;
  border-radius: 5px;
}

.grid3 {
  display: grid;
  margin: 16px;
  gap: 1rem 4rem;
  grid-template-columns: 1fr 1fr 1fr;
}
.grid {
  display: grid;
  margin: 16px;
  grid-template-columns: 1fr;
}
.bold {
  font-weight: bold;
  font-size: 1rem;
}
.prjectDiv {
  padding: 2rem;
  background: aliceblue;
}
.consultantR {
  background: aliceblue;
  padding: 1rem;
  border-left: 4px solid #607ebb;
  margin: 1rem;
  border-radius: 4px;
}
.special {
  padding: 1rem;
  margin: 1rem;
}
.capabilities {
  border: 1px solid #4444;
  border-radius: 30px;
  padding: 5px;
  margin-right: 8px;
  margin-bottom: 7px;
  display: inline-block;
  word-break: break-word;
  background-color: white;
}

@media only screen and (max-width: 600px) {
  .grid3 {
    grid-template-columns: 1fr 1fr !important;
  }
  .basicDiv {
    padding: 1rem !important;
  }
}

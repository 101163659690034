.icon-q {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.20000000298023224px solid #d8d3d3;
  border-radius: 2px;
  opacity: 1;
  padding: 3px;
  text-align: center;
  margin-right: 4px;
  display: flex;
  justify-content: space-evenly;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5e7bb7;
}

.grid-2-q {
  display: grid;
  gap: 0.4rem;
  margin-bottom: 0.6rem;
  grid-template-columns: 50px 1fr;
}
button {
  outline: none;
}
.ul ul {
  list-style: disc;
  padding: 0 2rem;
}
.css-vubbuv {
  color: #5e7bb7;
}
.ul button {
  background: #5e7bb7;
  border: 1px solid;
  border-radius: 30px;
  color: #fff;
  display: flex;
  /* margin: 0 0 0 auto; */
  padding: 8px 15px;
  cursor: pointer;
}
.ul div {
  display: flex;
  /* margin-top: 20px; */
}

.css-1qt4qyx-Mask {
  border-radius: 40px !important;
}

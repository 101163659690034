@import url("bulma/css/bulma.css");
@import url("material-design-icons/iconfont/material-icons.css");

html,
body {
  color: "#515d5e";
  font-family: "Nunito Sans";
}

.red {
  background: red !important;
}

ul {
  list-style: initial !important;
}

.uploadIcon {
  position: relative;
  top: 6px;
}

.uploadIcon:hover {
  color: #fff !important;
}

.uploadi:hover {
  color: #fff !important;
}

.errorPos {
  position: absolute;
  top: 36px;
  width: 400px;
  overflow: hidden;
}

.errorPos .css-x8f30b.Mui-error {
  position: absolute;
  top: 40px;
}

.errorPoss .css-1ujsryi-MuiFormHelperText-root.Mui-error {
  position: absolute;
  top: 40px;
  width: 400px;
  overflow: hidden;
}

.durationType .css-1ujsryi-MuiFormHelperText-root.Mui-error {
  width: 400px;
}

.disable {
  pointer-events: none;
  color: #aca7a7 !important;
}

.text-error {
  font-weight: 400;
  font-size: 0.75rem;
  margin-top: 4px;
  margin-bottom: 4px;
  color: #ff1744;
}

.pl-12 {
  padding-left: 2rem;
}

.css-9emuhu-MuiPaper-root-MuiDrawer-paper {
  max-height: 70% !important;
  border-radius: 1rem 1rem 0 0;
}

.text-right {
  text-align: right;
}

.paddingCenter {
  padding: 12px;
  /* justify-content: center; */
  margin: 0;
}

.grid2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.pc {
  padding: 9px;
}

/* clears the 'X' from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.css-1s2u09g-control {
  padding: 0;
}

.css-26l3qy-menu {
  z-index: 999 !important;
}

/* .css-1okebmr-indicatorSeparator, .css-tj5bde-Svg {
  display: none !important;
} */

.css-i4bv87-MuiSvgIcon-root {
  color: #607ebb;
}

.fPDpuU {
  margin: 0 !important;
}

.css-10t939o {
  width: 3rem;
  height: 1.4rem !important;
  min-height: 1.4rem !important;
  max-height: 1.4rem !important;
  margin-left: 1rem !important;
}

.css-109ivub {
  margin-top: 0 !important;
}

.hello {
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
  /* color: #4a4a4a; */
  display: block;
  /* padding: 1.25rem; */
  /* width: 80%; */
  margin: auto !important;
}

.hello .has-background-black {
  /* background-color: #0a0a0a !important; */
  /* background-color: lightcyan !important; */
  padding: 0;
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr !important;
}

.hello .has-background-black div:nth-child(2) {
  background: red !important;
  display: none;
}

.hello .columns.is-mobile > .column.is-2 {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr !important;
}

.hello .columns.is-mobile > .column.is-3 {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr !important;
  width: 100%;
}

.button {
  background-color: transparent !important;
  /* color: black !important; */
  font-weight: bold !important;
}

.hello .react-pdf__Page__canvas {
  /* height: 50% !important; */
}

.hello .name {
  height: 500px;
  overflow: auto;
  width: 100%;
}

.hello .name canvas {
  min-width: 100% !important;
  /* min-height: 500px; */
}

.borderGreen {
  background: #21ba4529;
  border-bottom: 3px solid #ffff;
  border-left: 5px solid #21ba45 !important;
}

.css-7x1yoy {
  top: 6px !important;
  right: 10px !important;
}

.css-11p5tv2-Close {
  top: 6px !important;
  right: 10px !important;
}

.tox-notification tox-notification--in tox-notification--warning {
  display: none !important;
}

.tox .tox-notification--in {
  opacity: none !important;
}

.tox .tox-notification--warn,
.tox .tox-notification--warning {
  display: none !important;
}

a:hover {
  color: #607ebb !important;
}

.fileNm {
  text-align: justify;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-left: 1rem !important;
}

.css-epd502 {
  min-height: 300px !important;
  overflow: hidden !important;
}

.MuiCalendarPicker-root {
  min-height: 300px !important;
  overflow: hidden !important;
}

.PrivatePickersSlideTransition-root {
  min-height: 200px !important;
}

.header_comp {
  text-align: right;
  margin-right: 14%;
  margin-top: 10%;
}

.talent_comp {
  text-align: left;
  position: relative;
  top: 15%;
  right: 50%;
}
.css-1dmzujt {
  border-radius: 30px !important;
  padding: 2px 11px !important;
  margin-top: -1px !important;
  margin-left: -3px !important;
  border: 1px solid #607ebb !important;
}

table td:not([align]),
table th:not([align]) {
  text-align: left;
  word-break: normal;
}

.tableLEE table td:not([align]),
table th:not([align]) {
  text-align: left !important;
  word-break: normal;
}



@media only screen and (max-width: 600px) {
  .text-right {
    text-align: left !important;
  }

   .pdf_modal {
    background: #fff;
    width: 90% !important;
    height: 90%;
    border-radius: 8px;
    outline: none;
    margin-top: 4.5%!important;
   }

   /* .iframepdf {
    width: 100% ;
    height: 70%;
    margin: auto;
    display: block;
    margin-top: 12px;
   } */

   .iframe {
    width: 100%;
   }
}

#ql-picker-options-0 {
  z-index: 10;
}


.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
